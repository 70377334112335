import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fish, Moon, Sun, RefreshCw, UserCircle2 } from 'lucide-react';

const Header = ({ userId, onResetUserId }) => {
  const navigate = useNavigate();
  const [isDark, setIsDark] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [inputId, setInputId] = useState('');
  const isAdmin = localStorage.getItem('adminToken');

  const playSound = (soundNumber) => {
    const audio = new Audio(`/whale/${soundNumber}.mp3`);
    audio.play().catch(error => console.error('Error playing sound:', error));
  };

  const handleIdClick = () => {
    setIsEditing(true);
    setInputId(userId || '');
  };

  const handleIdSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleIdChange(inputId);
    }
  };

  const handleIdBlur = () => {
    handleIdChange(inputId);
  };

  const handleIdChange = (newId) => {
    if (newId.trim()) {
      localStorage.setItem('userId', newId.trim());
      onResetUserId(newId.trim());
      setIsEditing(false);
    }
  };

  const handleReset = () => {
    onResetUserId();
  };

  return (
    <header className="bg-black/40 backdrop-blur-sm border-b border-blue-900/20">
      <div className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          {/* Logo and Title */}
          <div 
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => {
              playSound(2);
              navigate('/');
            }}
          >
            <Fish className="w-6 h-6 text-blue-400" />
            <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              WhaleCrypts
            </span>
          </div>
          
          {/* Navigation */}
          <div className="flex items-center space-x-4">
            <div className="bg-black/40 rounded-lg p-1 flex">
              <button 
                onClick={() => {
                  playSound(3);
                  navigate('/testnet');
                }}
                className="px-4 py-1.5 rounded-lg text-sm text-gray-400 hover:text-blue-400 hover:bg-blue-900/20 transition-all"
              >
                Testnet
              </button>
              <button 
                onClick={() => {
                  playSound(3);
                  navigate('/mainnet');
                }}
                className="px-4 py-1.5 rounded-lg text-sm text-gray-400 hover:text-blue-400 hover:bg-blue-900/20 transition-all"
              >
                Mainnet
              </button>
            </div>

            {/* ID Display */}
            <div className="flex items-center space-x-2 bg-black/40 px-3 py-1.5 rounded-lg">
              <span className="text-gray-400 text-sm">ID Key:</span>
              {isEditing ? (
                <input
                  type="text"
                  autoFocus
                  value={inputId}
                  onChange={(e) => setInputId(e.target.value)}
                  onKeyDown={handleIdSubmit}
                  onBlur={handleIdBlur}
                  onClick={(e) => e.stopPropagation()}
                  className="bg-transparent text-blue-400 font-mono text-sm outline-none border-b border-blue-400/20 w-32"
                  placeholder="Enter ID Key"
                />
              ) : (
                <span 
                  onClick={handleIdClick}
                  className="text-blue-400 font-mono text-sm cursor-pointer hover:text-blue-300 transition-colors"
                >
                  {userId || 'undefined'}
                </span>
              )}
              <button
                onClick={handleReset}
                className="p-1 rounded-lg hover:bg-blue-900/20 transition-all"
                title="Reset ID"
              >
                <RefreshCw className="w-4 h-4 text-blue-400" />
              </button>
            </div>

            {/* Admin Button */}
            <button 
              onClick={() => {
                playSound(2);
                navigate(isAdmin ? '/admin' : '/admin/login');
              }}
              className="p-2 rounded-lg bg-black/40 hover:bg-blue-900/20 transition-all"
              title={isAdmin ? 'Admin Panel' : 'Admin Login'}
            >
              <UserCircle2 className="w-5 h-5 text-blue-400" />
            </button>

            {/* Theme Toggle */}
            <button 
              onClick={() => setIsDark(!isDark)}
              className="p-2 rounded-lg bg-black/40 hover:bg-blue-900/20 transition-all"
            >
              {isDark ? 
                <Moon className="w-5 h-5 text-blue-400" /> : 
                <Sun className="w-5 h-5 text-yellow-400" />
              }
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
import React, { useState, useEffect } from 'react';
import { API } from './utils/constants';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import WhaleHome from './components/WhaleHome';
import TestnetPage from './components/TestnetPage';
import MainnetPage from './components/MainnetPage';
import AdminLogin from './components/AdminLogin';
import AdminPanel from './components/AdminPanel';
import { Toaster } from 'react-hot-toast';
function App() {
  const [userId, setUserId] = useState(() => {
    return localStorage.getItem('userId') || null;
  });

  useEffect(() => {
    if (!userId) {
      generateNewUserId();
    }
  }, []);

  const generateNewUserId = async (customId = null) => {
    try {
      if (customId) {
        localStorage.setItem('userId', customId);
        setUserId(customId);
        return;
      }

      const response = await fetch(`${API.baseUrl}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Failed to generate user ID');
      }

      const data = await response.json();
      if (data.userId) {
        localStorage.setItem('userId', data.userId);
        setUserId(data.userId);
      }
    } catch (error) {
      console.error('Error generating user ID:', error);
      // Hata durumunda geçici bir ID oluştur
      const tempId = Math.random().toString().substring(2, 17); // 15 haneli sayı
      localStorage.setItem('userId', tempId);
      setUserId(tempId);
    }
  };

  const resetUserId = (customId = null) => {
    localStorage.removeItem('userId');
    if (customId) {
      localStorage.setItem('userId', customId);
      setUserId(customId);
    } else {
      generateNewUserId();
    }
  };

  // Protected Route Component
  const ProtectedRoute = ({ children }) => {
    const isAdmin = localStorage.getItem('adminToken');
    if (!isAdmin) {
      return <Navigate to="/admin/login" />;
    }
    return children;
  };

  return (
    <Router>
      <Toaster/>
      <div className="app bg-gradient-to-br from-black via-blue-950 to-black min-h-screen">
        <Header userId={userId} onResetUserId={resetUserId} />
        <Routes>
          <Route path="/" element={<WhaleHome />} />
          <Route path="/testnet" element={<TestnetPage />} />
          <Route path="/mainnet" element={<MainnetPage />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
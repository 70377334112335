import React, { useState, useEffect } from 'react';
import { API } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { 
  TrendingUp, 
  Users, 
  ArrowLeft, 
  Trash2, 
  Edit2, 
  Fish,
  CheckCircle,
  AlertTriangle,
  Plus,
  RefreshCw
} from 'lucide-react';
import TokenTemplate from './TokenTemplate';
import toast from 'react-hot-toast';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('tokens');
  const [network, setNetwork] = useState('testnet');
  const [viewMode, setViewMode] = useState('active');
  const [tokens, setTokens] = useState([]);
  const [completedTokens, setCompletedTokens] = useState([]);
  const [users, setUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingToken, setEditingToken] = useState(null);
  const [editingCompletedToken, setEditingCompletedToken] = useState(null);
  const [error, setError] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  // Form states
  const [formData, setFormData] = useState({
    name: '',
    symbol: '',
    imageUrl: '',
    whaleLink: '',
    initialPrice: '',
    targetPrice: '',
    marketCap: '',
    duration: 60,
    durationType: 'minutes',
    network: 'testnet',
    performanceType: 'success',
    performanceMultiplier: ''
  });

  const [updateForm, setUpdateForm] = useState({
    currentPrice: '',
    performanceType: 'success',
    performanceMultiplier: ''
  });

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin/login');
    } else {
      fetchData();
      const interval = setInterval(fetchData, 30000);
      return () => clearInterval(interval);
    }
  }, [activeTab, network, viewMode]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError('');
      const token = localStorage.getItem('adminToken');
      
      if (!token) {
        navigate('/admin/login');
        return;
      }
  
      if (activeTab === 'tokens') {
        const activeResponse = await fetch(`https://${API.baseUrl}/api/tokens/${network}`);
        const completedResponse = await fetch(`https://${API.baseUrl}/api/tokens/${network}/completed`);
        
        if (!activeResponse.ok || !completedResponse.ok) {
          throw new Error('Failed to fetch tokens');
        }
        
        const activeData = await activeResponse.json();
        const completedData = await completedResponse.json();
        
        setTokens(activeData.filter(token => token.status === 'active'));
        setCompletedTokens(completedData.filter(token => 
          token.status === 'completed' && token.network === network
        ));
      } else if (activeTab === 'users') {
        const response = await fetch(`${API.baseUrl}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (!response.ok) throw new Error('Failed to fetch users');
        
        const data = await response.json();
        setUsers(data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('adminToken');
      const submitData = {
        ...formData,
        network,
        status: 'active'
      };
      
      if (isEditing && editingToken) {
        const response = await fetch(`https://${API.baseUrl}/api/admin/tokens/${editingToken._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(submitData)
        });

        if (!response.ok) throw new Error('Failed to update token');
        toast.success('Token updated successfully');
      } else {
        const response = await fetch(`${API.baseUrl}/api/admin/tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(submitData)
        });

        if (!response.ok) throw new Error('Failed to create token');
        toast.success('Token created successfully');
      }
      
      fetchData();
      resetForm();
      setShowAddForm(false);
    } catch (error) {
      console.error('Error with token:', error);
      toast.error('Error with token operation');
    }
  };

  const handleEdit = (token) => {
    setIsEditing(true);
    setEditingToken(token);
    setShowAddForm(true);
    setFormData({
      name: token.name,
      symbol: token.symbol,
      imageUrl: token.imageUrl,
      whaleLink: token.whaleLink,
      initialPrice: token.initialPrice,
      targetPrice: token.targetPrice,
      marketCap: token.marketCap,
      duration: token.duration,
      durationType: token.durationType || 'minutes',
      network: token.network,
      performanceType: token.performanceType || 'success',
      performanceMultiplier: token.performanceMultiplier || ''
    });
  };

  const handleEditCompleted = (token) => {
    setEditingCompletedToken(token);
    setUpdateForm({
      currentPrice: token.currentPrice || '',
      performanceType: token.performanceType || 'success',
      performanceMultiplier: token.performanceMultiplier || '2x'
    });
  };

  const handleUpdateCompleted = async () => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      const response = await fetch(`https://${API.baseUrl}/api/admin/tokens/${editingCompletedToken._id}/update-completed`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`
        },
        body: JSON.stringify(updateForm)
      });

      if (!response.ok) throw new Error('Failed to update completed token');

      toast.success('Token updated successfully');
      setEditingCompletedToken(null);
      fetchData();
    } catch (error) {
      console.error('Error updating completed token:', error);
      toast.error('Failed to update token');
    }
  };

  const deleteToken = async (tokenId) => {
    if (!window.confirm('Are you sure you want to delete this token?')) return;
    
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`https://${API.baseUrl}/api/admin/tokens/${tokenId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!response.ok) throw new Error('Failed to delete token');
      
      toast.success('Token deleted successfully');
      await fetchData();
    } catch (error) {
      console.error('Error deleting token:', error);
      toast.error('Error deleting token');
    }
  };

  const handleUserAction = async (userId, action) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`https://${API.baseUrl}/api/admin/${action}-access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId })
      });

      if (!response.ok) throw new Error(`Failed to ${action} user`);
      
      toast.success(`Access ${action}ed successfully`);
      await fetchData();
    } catch (error) {
      console.error(`Error ${action}ing access:`, error);
      toast.error(`Error ${action}ing access`);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      symbol: '',
      imageUrl: '',
      whaleLink: '',
      initialPrice: '',
      targetPrice: '',
      marketCap: '',
      duration: 60,
      durationType: 'minutes',
      network: network,
      performanceType: 'success',
      performanceMultiplier: ''
    });
    setIsEditing(false);
    setEditingToken(null);
    setError('');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-black flex items-center justify-center">
        <div className="text-blue-400 flex items-center gap-2">
          <RefreshCw className="w-5 h-5 animate-spin" />
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-black text-white p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate('/')}
              className="text-gray-400 hover:text-white flex items-center space-x-2 transition-colors"
            >
              <ArrowLeft size={20} />
              <span>Back to Home</span>
            </button>
            <h1 className="text-3xl font-bold">Admin Panel</h1>
          </div>
          <button
            onClick={() => {
              localStorage.removeItem('adminToken');
              navigate('/');
            }}
            className="px-4 py-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30 transition-all"
          >
            Logout
          </button>
        </div>

        {error && (
          <div className="bg-red-500/20 text-red-400 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}

        {/* Tabs */}
        <div className="flex gap-4 mb-6">
          <button
            onClick={() => setActiveTab('tokens')}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
              activeTab === 'tokens' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            <TrendingUp size={20} />
            Tokens
          </button>
          <button
            onClick={() => setActiveTab('users')}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
              activeTab === 'users' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            <Users size={20} />
            Users
          </button>
        </div>

        {activeTab === 'tokens' && (
          <div>
            {/* Network & View Selection */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex gap-4">
                <select
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                  className="bg-gray-800 px-4 py-2 rounded-lg text-gray-200"
                >
                  <option value="testnet">Testnet</option>
                  <option value="mainnet">Mainnet</option>
                </select>

                <div className="flex gap-2">
                  <button
                    onClick={() => setViewMode('active')}
                    className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                      viewMode === 'active' 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-gray-800 text-gray-400'
                    }`}
                  >
                    <TrendingUp size={16} />
                    Active
                  </button>
                  <button
                    onClick={() => setViewMode('completed')}
                    className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                      viewMode === 'completed'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-800 text-gray-400'
                    }`}
                  >
                    <CheckCircle size={16} />
                    Completed
                  </button>
                </div>
              </div>

              {viewMode === 'active' && (
                <button
                  onClick={() => setShowAddForm(!showAddForm)}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Plus size={20} />
                  Add Token
                </button>
              )}
            </div>

            {/* Add/Edit Form with Preview */}
            {showAddForm && viewMode === 'active' && (
              <div className="grid grid-cols-2 gap-6 mb-8">
                {/* Form Side */}
                <div className="bg-black/40 backdrop-blur-sm border border-blue-900/20 rounded-lg p-6">
                  <h3 className="text-xl font-bold mb-4">
                    {isEditing ? 'Edit Token' : 'Add New Token'}
                  </h3>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        className="bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Symbol"
                        value={formData.symbol}
                        onChange={(e) => setFormData({...formData, symbol: e.target.value})}
                        className="bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                        required
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        placeholder="Image URL"
                        value={formData.imageUrl}
                        onChange={(e) => setFormData({...formData, imageUrl: e.target.value})}
                        className="bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                        required/>
                        <input
                          type="text"
                          placeholder="Whale Link"
                          value={formData.whaleLink}
                          onChange={(e) => setFormData({...formData, whaleLink: e.target.value})}
                          className="bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                          required
                        />
                      </div>
  
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-gray-400 mb-2">Initial Price ($)</label>
                          <input
                            type="number"
                            step="0.000001"
                            value={formData.initialPrice}
                            onChange={(e) => setFormData({...formData, initialPrice: e.target.value})}
                            className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-gray-400 mb-2">Target Price ($)</label>
                          <input
                            type="number"
                            step="0.000001"
                            value={formData.targetPrice}
                            onChange={(e) => setFormData({...formData, targetPrice: e.target.value})}
                            className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                            required
                          />
                        </div>
                      </div>
  
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-gray-400 mb-2">Market Cap ($)</label>
                          <input
                            type="number"
                            value={formData.marketCap}
                            onChange={(e) => setFormData({...formData, marketCap: e.target.value})}
                            className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                            required
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <div>
                            <label className="block text-gray-400 mb-2">Duration</label>
                            <input
                              type="number"
                              value={formData.duration}
                              onChange={(e) => setFormData({...formData, duration: parseInt(e.target.value)})}
                              className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                              required
                            />
                          </div>
                          <div>
                            <label className="block text-gray-400 mb-2">Type</label>
                            <select
                              value={formData.durationType}
                              onChange={(e) => setFormData({...formData, durationType: e.target.value})}
                              className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                            >
                              <option value="minutes">Minutes</option>
                              <option value="hours">Hours</option>
                              <option value="days">Days</option>
                            </select>
                          </div>
                        </div>
                      </div>
  
                      <div className="flex gap-4">
                        <button
                          type="submit"
                          className="flex-1 bg-blue-600 text-white rounded py-2 hover:bg-blue-700 transition-colors"
                        >
                          {isEditing ? 'Update Token' : 'Add Token'}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            resetForm();
                            setShowAddForm(false);
                          }}
                          className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 transition-colors"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
  
                  {/* Preview Side */}
                  <div className="bg-black/40 backdrop-blur-sm border border-blue-900/20 rounded-lg p-6">
                    <h3 className="text-xl font-bold mb-4">Preview</h3>
                    <TokenTemplate 
                      token={{
                        ...formData,
                        _id: 'preview',
                        status: isEditing ? 'completed' : 'active',
                        startTime: new Date(),
                      }} 
                    />
                  </div>
                </div>
              )}
  
              {/* Token Lists */}
              <div className="space-y-4">
                {viewMode === 'active' ? (
                  tokens.map(token => (
                    <div key={token._id} className="relative group">
                      <TokenTemplate token={token} />
                      <div className="absolute top-4 right-4 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <button
                          onClick={() => handleEdit(token)}
                          className="p-2 text-blue-400 hover:bg-blue-500/20 rounded"
                          title="Edit Token"
                        >
                          <Edit2 size={18} />
                        </button>
                        <button
                          onClick={() => deleteToken(token._id)}
                          className="p-2 text-red-400 hover:bg-red-500/20 rounded"
                          title="Delete Token"
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  completedTokens.map(token => (
                    <div key={token._id} className="relative">
                      <TokenTemplate 
                        token={token} 
                        onEdit={handleEditCompleted} 
                        isCompleted={true}
                      />
                      
                      {/* Edit Modal for Completed Tokens */}
                      {editingCompletedToken?._id === token._id && (
                        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                          <div className="bg-gray-900 p-6 rounded-lg w-full max-w-md">
                            <h3 className="text-xl font-bold mb-4">Edit Completed Token</h3>
                            
                            <div className="space-y-4">
                              <div>
                                <label className="block text-sm text-gray-400 mb-1">Current Price ($)</label>
                                <input
                                  type="number"
                                  value={updateForm.currentPrice}
                                  onChange={(e) => setUpdateForm({
                                    ...updateForm,
                                    currentPrice: e.target.value
                                  })}
                                  className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                                />
                              </div>
  
                              <div>
                                <label className="block text-sm text-gray-400 mb-1">Performance Type</label>
                                <select
                                  value={updateForm.performanceType}
                                  onChange={(e) => setUpdateForm({
                                    ...updateForm,
                                    performanceType: e.target.value
                                  })}
                                  className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                                >
                                  <option value="success">Success (Green)</option>
                                  <option value="failed">Failed (Red)</option>
                                </select>
                              </div>
  
                              <div>
                                <label className="block text-sm text-gray-400 mb-1">Multiplier</label>
                                <input
                                  type="text"
                                  placeholder="2x, 3x, etc."
                                  value={updateForm.performanceMultiplier}
                                  onChange={(e) => setUpdateForm({
                                    ...updateForm,
                                    performanceMultiplier: e.target.value
                                  })}
                                  className="w-full bg-black/40 border border-blue-900/20 rounded px-4 py-2 text-gray-100"
                                />
                              </div>
  
                              <div className="flex gap-4 mt-6">
                                <button
                                  onClick={handleUpdateCompleted}
                                  className="flex-1 bg-blue-600 text-white rounded py-2 hover:bg-blue-700 transition-all"
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => setEditingCompletedToken(null)}
                                  className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 transition-all"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
  
          {/* Users Management */}
          {activeTab === 'users' && (
            <div className="space-y-4">
              {users.map(user => (
                <div key={user._id} className="bg-black/40 backdrop-blur-sm rounded-lg p-6 border border-blue-900/20">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-bold flex items-center gap-2">
                        ID: {user.userId}
                        {user.isWhale && (
                          <span className="bg-blue-500/20 text-blue-400 text-sm px-3 py-1 rounded-full flex items-center gap-1">
                            <Fish className="w-4 h-4" />
                            Whale
                          </span>
                        )}
                      </h3>
                      <p className="text-gray-400">IP: {user.ipAddress}</p>
                      {user.walletAddress && (
                        <p className="text-gray-400 mt-2">Wallet: {user.walletAddress}</p>
                      )}
                    </div>
                    
                    <div className="flex items-center gap-4">
                      {user.subscription?.status === 'pending' && (
                        <>
                          <button
                            onClick={() => handleUserAction(user.userId, 'approve')}
                            className="px-4 py-2 bg-green-500/20 text-green-400 rounded-lg hover:bg-green-500/30 transition-colors"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => handleUserAction(user.userId, 'reject')}
                            className="px-4 py-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30 transition-colors"
                          >
                            Reject
                          </button>
                        </>
                      )}
                      <div className="bg-gray-800/50 px-4 py-2 rounded-lg">
                        <p className="text-sm font-medium">
                          Status: {user.subscription?.status || 'No subscription'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default AdminPanel;
// TestnetPage.js
import React, { useState, useEffect } from 'react';
import { API } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import TokenTemplate from './TokenTemplate';
import { toast } from 'react-hot-toast';

const TestnetPage = () => {
  const navigate = useNavigate();
  const [activeTokens, setActiveTokens] = useState([]);
  const [completedTokens, setCompletedTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTokens();
    const interval = setInterval(fetchTokens, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchTokens = async () => {
    try {
      setLoading(true);
      const [activeResponse, completedResponse] = await Promise.all([
        fetch(`${API.baseUrl}/api/tokens/testnet`),
        fetch(`${API.baseUrl}/api/tokens/testnet/completed`)
      ]);

      if (!activeResponse.ok || !completedResponse.ok) {
        throw new Error('Failed to fetch tokens');
      }

      const [activeData, completedData] = await Promise.all([
        activeResponse.json(),
        completedResponse.json()
      ]);

      setActiveTokens(activeData.filter(token => token.network === 'testnet' && token.status === 'active'));
      setCompletedTokens(completedData.filter(token => token.network === 'testnet' && token.status === 'completed'));
    } catch (error) {
      console.error('Error fetching tokens:', error);
      toast.error('Error fetching tokens');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-black flex items-center justify-center">
        <div className="text-blue-400">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-black animate-gradient-slow">
      {/* Header */}
      <header className="bg-black/30 backdrop-blur-sm border-b border-blue-900/20">
        <div className="container mx-auto px-4 py-4">
          <button 
            onClick={() => navigate('/')}
            className="text-gray-400 hover:text-white flex items-center space-x-2 transition-colors"
          >
            <ArrowLeft size={20} />
            <span>Back</span>
          </button>
        </div>
      </header>

      <div className="container mx-auto px-4 py-8">
        {/* Active Tokens Section */}
        <div className="space-y-6 mb-12">
          <div className="flex items-center">
            <h2 className="text-xl font-bold text-white">Active Tokens</h2>
            <div className="ml-3 px-3 py-1 bg-blue-500/20 rounded-full">
              <span className="text-blue-400 text-sm">{activeTokens.length}</span>
            </div>
          </div>
          
          <div className="space-y-4">
            {activeTokens.map((token) => (
              <TokenTemplate 
                key={token._id} 
                token={token}
              />
            ))}
            {activeTokens.length === 0 && (
              <div className="text-center py-8 bg-black/20 rounded-lg border border-blue-900/10">
                <p className="text-gray-400">No active tokens found</p>
              </div>
            )}
          </div>
        </div>

        {/* Completed Tokens Section */}
        <div className="space-y-6">
          <div className="flex items-center">
            <h2 className="text-xl font-bold text-white">Completed Tokens</h2>
            <div className="ml-3 px-3 py-1 bg-blue-500/20 rounded-full">
              <span className="text-blue-400 text-sm">{completedTokens.length}</span>
            </div>
          </div>
          
          <div className="space-y-4">
            {completedTokens.map((token) => (
              <TokenTemplate 
                key={token._id} 
                token={token}
              />
            ))}
            {completedTokens.length === 0 && (
              <div className="text-center py-8 bg-black/20 rounded-lg border border-blue-900/10">
                <p className="text-gray-400">No completed tokens found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestnetPage;
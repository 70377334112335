import React, { useState } from 'react';
import { API } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Wallet, Clock, Users, ArrowRight } from 'lucide-react';
import { toast } from 'react-hot-toast';

const WhaleHome = () => {
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState('');
  const [verificationResult, setVerificationResult] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = localStorage.getItem('userId');

  const playSound = (soundNumber) => {
    const audio = new Audio(`/whale/${soundNumber}.mp3`);
    audio.play().catch(error => console.error('Error playing sound:', error));
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    playSound(plan === '7days' ? 7 : 14);
  };

  const handleWalletSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedPlan) {
      toast.error('Please select a plan first');
      return;
    }

    if (!walletAddress) {
      toast.error('Please enter wallet address');
      return;
    }

    setIsSubmitting(true);
    
    try {
      // Önce wallet'ı kontrol et
      const verifyResponse = await fetch(`${API.baseUrl}/api/verify-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userId, 
          walletAddress 
        }),
      });
      
      const verifyData = await verifyResponse.json();
      setVerificationResult(verifyData);

      // Subscription'ı kaydet
      const subscribeResponse = await fetch(`${API.baseUrl}/api/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userId, 
          plan: selectedPlan,
          walletAddress
        }),
      });

      const subscribeData = await subscribeResponse.json();

      if (subscribeData.success) {
        playSound(1);
        toast.success('Application submitted successfully!', {
          duration: 5000,
          position: 'top-center',
          style: {
            background: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(59, 130, 246, 0.2)',
          },
        });

        // Form'u resetle
        setWalletAddress('');
        setSelectedPlan(null);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting application. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-black animate-gradient-slow">
      {/* Hero Section */}
      <div className="container mx-auto px-6">
        <div className="text-center py-16 space-y-6">
          <h1 className="text-4xl md:text-5xl font-bold">
            <span className="text-gray-200">Discover the </span>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
              transformative power
            </span>
            <br />
            <span className="text-orange-400">of whale tracking</span>
          </h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Join the elite group of whale traders and access real-time market insights
          </p>
        </div>

        {/* Steps Section */}
        <div className="grid md:grid-cols-3 gap-8 py-12">
          {/* Step 1 */}
          <div className="bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-blue-900/20 hover:border-blue-500/30 transition-all">
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Wallet className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">Phase 1</h3>
            </div>
            <h4 className="text-blue-400 font-semibold mb-2">Wallet Requirements</h4>
            <p className="text-gray-400">
              Submit your wallet address with a minimum trading balance of $10,000 to qualify for the whale pod.
            </p>
          </div>

          {/* Step 2 */}
          <div className="bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-blue-900/20 hover:border-blue-500/30 transition-all">
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Clock className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">Phase 2</h3>
            </div>
            <h4 className="text-blue-400 font-semibold mb-2">24-Hour Hold Period</h4>
            <p className="text-gray-400">
              Once you join, maintain positions for 24 hours. Early withdrawals result in removal from the whale pod.
            </p>
          </div>

          {/* Step 3 */}
          <div className="bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-blue-900/20 hover:border-blue-500/30 transition-all">
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Users className="w-6 h-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">Phase 3</h3>
            </div>
            <h4 className="text-blue-400 font-semibold mb-2">Follow The Pod</h4>
            <p className="text-gray-400">
              Once claimed, follow the pod's movements for 24 hours. Stay committed to maximize potential returns.
            </p>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-12 space-y-8">
          <div>
            <p className="text-gray-400 mb-6">
              Start your journey with WhaleCrypts today
            </p>
            <button 
              onClick={() => {
                playSound(2);
                navigate('/testnet');
              }}
              className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all inline-flex items-center space-x-2 group"
            >
              <span>Start Tracking</span>
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>

          <div>
            <p className="text-gray-400 mb-6">Want to access mainnet features?</p>
            <div className="max-w-4xl mx-auto">
              {/* Subscription Plans */}
              <div className="grid md:grid-cols-2 gap-6 mb-8">
                {/* 7 Days Plan */}
                <div 
                  onClick={() => handlePlanSelect('7days')}
                  className={`group bg-black/40 backdrop-blur-sm p-8 rounded-xl border cursor-pointer transition-all transform hover:scale-105
                    ${selectedPlan === '7days' ? 'border-blue-500 bg-blue-900/10' : 'border-blue-900/20 hover:border-blue-500/50'}`}
                >
                  <div className="relative overflow-hidden">
                    <h4 className="text-2xl font-bold text-white mb-2">7 Days Access</h4>
                    <p className="text-4xl text-blue-400 font-bold mb-6">$170</p>
                    <ul className="text-gray-400 space-y-3">
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Full mainnet access
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Real-time tracking
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Basic support
                      </li>
                    </ul>
                    <div className="absolute top-0 right-0 h-full w-1/2 bg-gradient-to-l from-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"/>
                  </div>
                </div>

                {/* 14 Days Plan */}
                <div 
                  onClick={() => handlePlanSelect('14days')}
                  className={`group bg-black/40 backdrop-blur-sm p-8 rounded-xl border cursor-pointer transition-all transform hover:scale-105
                    ${selectedPlan === '14days' ? 'border-blue-500 bg-blue-900/10' : 'border-blue-900/20 hover:border-blue-500/50'}`}
                >
                  <div className="relative overflow-hidden">
                    <div className="absolute top-4 right-4 bg-blue-500/20 text-blue-400 px-3 py-1 rounded-full text-sm">
                      Best Value
                    </div>
                    <h4 className="text-2xl font-bold text-white mb-2">14 Days Access</h4>
                    <p className="text-4xl text-blue-400 font-bold mb-6">$300</p>
                    <ul className="text-gray-400 space-y-3">
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Full mainnet access
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Real-time tracking
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Priority support
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-blue-400 rounded-full mr-2"></span>
                        Extended access
                      </li>
                    </ul>
                    <div className="absolute top-0 right-0 h-full w-1/2 bg-gradient-to-l from-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"/>
                  </div>
                </div>
              </div>

              {/* Application Form */}
              {selectedPlan && (
                <div className="max-w-xl mx-auto bg-black/40 backdrop-blur-sm rounded-xl p-8 border border-blue-900/20">
                  <h3 className="text-2xl font-bold text-white mb-6 text-center">Submit Application</h3>
                  <form onSubmit={handleWalletSubmit} className="space-y-6">
                    <div>
                      <label className="block text-gray-400 mb-2">Solana Wallet Address</label>
                      <input
                        type="text"
                        placeholder="Enter your Solana wallet address"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                        className="w-full bg-black/40 border border-blue-900/20 rounded-lg px-4 py-3 text-gray-100 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                        disabled={isSubmitting}
                        required
                      />
                    </div>

                    <div className="bg-blue-900/20 rounded-lg p-4">
                      <p className="text-gray-400 text-sm">
                        Send payment to:
                      </p>
                      <code className="block bg-black/40 p-3 rounded mt-2 text-blue-400 break-all text-sm">
                        HbFZSz8U9T1rXko4h9sXjsYrN8uLT62zhfpuapRSNW9Y
                      </code>
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`w-full bg-blue-600 text-white rounded-lg py-4 hover:bg-blue-700 transition-all flex items-center justify-center gap-2 text-lg font-medium ${
                        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <Wallet className="w-5 h-5" />
                      {isSubmitting ? 'Submitting...' : 'Submit Application'}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhaleHome;
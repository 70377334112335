// Base URLs based on environment
const BASE_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

// API configuration
export const API = {
  baseUrl: BASE_URL,
  
  // Auth endpoints
  auth: {
    adminLogin: '/api/admin/login',
  },

  // User endpoints
  users: {
    create: '/api/users',
    checkAccess: (userId) => `/api/users/check-access/${userId}`,
    subscribe: '/api/subscribe',
    verifyWallet: '/api/verify-wallet',
    subscription: (userId) => `/api/subscription/${userId}`,
    getAllUsers: '/api/admin/users',
  },

  // Token endpoints
  tokens: {
    getByNetwork: (network) => `/api/tokens/${network}`,
    getCompleted: (network) => `/api/tokens/${network}/completed`,
    create: '/api/admin/tokens',
    update: (tokenId) => `/api/admin/tokens/${tokenId}`,
    delete: (tokenId) => `/api/admin/tokens/${tokenId}`,
    complete: (tokenId) => `/api/admin/tokens/${tokenId}/complete`,
    updateCompleted: (tokenId) => `/api/admin/tokens/${tokenId}/update-completed`,
    updateResult: (tokenId) => `/api/admin/tokens/${tokenId}/result`,
    getHistory: (network) => `/api/tokens/history/${network}`,
  },

  // Admin endpoints
  admin: {
    users: '/api/admin/users',
    approveAccess: '/api/admin/approve-access',
    rejectAccess: '/api/admin/reject-access',
    grantAccess: '/api/admin/grant-access',
  }
};

// Subscription plans
export const SUBSCRIPTION_PLANS = {
  weekly: {
    id: '7days',
    name: '7 Days Access',
    price: 170,
    features: [
      'Full mainnet access',
      'Real-time tracking',
      'Basic support'
    ]
  },
  biweekly: {
    id: '14days',
    name: '14 Days Access',
    price: 300,
    features: [
      'Full mainnet access',
      'Real-time tracking',
      'Priority support',
      'Extended access'
    ]
  }
};

// Token configuration
export const TOKEN_CONFIG = {
  refreshInterval: 30000, // 30 seconds
  statuses: {
    active: 'active',
    completed: 'completed'
  },
  networks: {
    testnet: 'testnet',
    mainnet: 'mainnet'
  },
  durationTypes: ['minutes', 'hours', 'days']
};

// Payment configuration
export const PAYMENT_CONFIG = {
  walletAddress: 'HbFZSz8U9T1rXko4h9sXjsYrN8uLT62zhfpuapRSNW9Y',
  minWhaleBalance: 10000 // Minimum balance for whale status
};

// Admin configuration
export const ADMIN_CONFIG = {
  tablePageSize: 10,
  maxImageSize: 2 * 1024 * 1024 // 2MB
};

// Theme configuration
export const THEME = {
  colors: {
    primary: '#0A1929',
    secondary: '#000212',
    success: '#10B981',
    error: '#EF4444',
    warning: '#F59E0B'
  }
};

// Form validation
export const VALIDATION = {
  wallet: {
    minLength: 32,
    maxLength: 44
  }
};

// Error messages
export const ERROR_MESSAGES = {
  invalidWallet: 'Please enter a valid wallet address',
  networkError: 'Network error. Please try again later',
  unauthorized: 'Unauthorized access',
  invalidCredentials: 'Invalid credentials'
};

// Success messages
export const SUCCESS_MESSAGES = {
  applicationSubmitted: 'Application submitted successfully!',
  tokenCreated: 'Token created successfully',
  tokenUpdated: 'Token updated successfully',
  tokenDeleted: 'Token deleted successfully'
};
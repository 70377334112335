import React from 'react';
import { TrendingUp, ExternalLink, CheckCircle, Edit2 } from 'lucide-react';

const TokenTemplate = ({ token, onEdit, isCompleted = false }) => {
  // Fiyat değişimini hesapla
  const calculatePriceChange = () => {
    if (!token.currentPrice || !token.initialPrice) return 0;
    const change = ((token.currentPrice - token.initialPrice) / token.initialPrice * 100);
    return change.toFixed(2);
  };

  // Süre formatını düzenle
  const formatDuration = (duration, durationType) => {
    if (token.status === 'completed') return 'Completed';
    
    const start = new Date(token.startTime).getTime();
    const now = new Date().getTime();
    let timeInMillis;
    
    switch (durationType || 'minutes') {
      case 'minutes':
        timeInMillis = duration * 60 * 1000;
        break;
      case 'hours':
        timeInMillis = duration * 60 * 60 * 1000;
        break;
      case 'days':
        timeInMillis = duration * 24 * 60 * 60 * 1000;
        break;
      default:
        timeInMillis = duration * 60 * 1000;
    }
    
    const remaining = timeInMillis - (now - start);
    if (remaining <= 0) return 'Completed';
    
    const hours = Math.floor((remaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((remaining % (60 * 60 * 1000)) / (60 * 1000));
    
    return `${hours}h ${minutes}m`;
  };

  const priceChange = calculatePriceChange();
  const isPositiveChange = parseFloat(priceChange) >= 0;
  const displayedChange = token.status === 'completed' ? priceChange : '+0%';

  return (
    <div 
      className={`group relative bg-gradient-to-r from-black/40 to-blue-950/30 backdrop-blur-sm 
        border border-blue-500/20 rounded-lg p-6 transition-all duration-300
        ${token.status === 'completed' 
          ? isPositiveChange 
            ? 'hover:from-green-950/20 hover:to-green-900/10 hover:border-green-500/30' 
            : 'hover:from-red-950/20 hover:to-red-900/10 hover:border-red-500/30'
          : 'hover:from-blue-950/20 hover:to-blue-900/10 hover:border-blue-500/30'
        }`}
    >
      {/* Main Content */}
      <div className="flex items-center justify-between">
        {/* Left Side */}
        <div className="flex items-center space-x-6">
          {/* Token Icon & Info */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <img 
                src={token.imageUrl} 
                alt={token.name}
                className={`w-12 h-12 rounded-full ring-2 transition-all duration-300 
                  ${token.status === 'completed'
                    ? isPositiveChange 
                      ? 'ring-green-500/40 group-hover:ring-green-400/50' 
                      : 'ring-red-500/40 group-hover:ring-red-400/50'
                    : 'ring-blue-500/40 group-hover:ring-blue-400/50'
                  }`}
              />
              <div className={`absolute -bottom-1 -right-1 p-1.5 rounded-full transition-all duration-300
                ${token.status === 'completed'
                  ? isPositiveChange
                    ? 'bg-green-500/20 group-hover:bg-green-500/30'
                    : 'bg-red-500/20 group-hover:bg-red-500/30'
                  : 'bg-blue-500/20 group-hover:bg-blue-500/30'
                }`}
              >
                <TrendingUp className={`w-3 h-3 
                  ${token.status === 'completed'
                    ? isPositiveChange ? 'text-green-400' : 'text-red-400'
                    : 'text-blue-400'
                  }`} 
                />
              </div>
            </div>

            <div>
              <h3 className="text-xl font-bold bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
                {token.name}
              </h3>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">{token.symbol}</span>
                <span className={`text-sm font-medium px-2 py-0.5 rounded-full
                  ${token.status === 'completed'
                    ? isPositiveChange 
                      ? 'text-green-400 bg-green-500/10' 
                      : 'text-red-400 bg-red-500/10'
                    : 'text-blue-400 bg-blue-500/10'
                  }`}
                >
                  {displayedChange}
                </span>
              </div>
            </div>
          </div>

          {/* Whale Alert Link */}
          {token.whaleLink && (
            <a
              href={token.whaleLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 rounded-lg font-medium 
                transition-all duration-300 bg-blue-500/10 text-blue-400 
                hover:bg-blue-500/20"
            >
              <span>Whale Alert</span>
              <ExternalLink size={14} />
            </a>
          )}
        </div>

        {/* Right Side - Stats */}
        <div className="flex items-center space-x-8">
          {/* Initial Price */}
          <div className="text-center">
            <div className="text-sm text-gray-400 mb-1">Initial</div>
            <span className="text-lg font-bold text-blue-400">
              ${token.initialPrice}
            </span>
          </div>

          {/* Final/Target Price */}
          <div className="text-center">
            <div className="text-sm text-gray-400 mb-1">
              {token.status === 'completed' ? 'Final' : 'Target'}
            </div>
            <span className={`text-lg font-bold
              ${token.status === 'completed'
                ? isPositiveChange ? 'text-green-400' : 'text-red-400'
                : 'text-blue-400'
              }`}
            >
              ${token.status === 'completed' ? token.currentPrice : token.targetPrice}
            </span>
          </div>

          {/* Amount */}
          <div className="text-center">
            <div className="text-sm text-gray-400 mb-1">Amount</div>
            <span className="text-lg font-bold text-purple-400">
              ${Number(token.marketCap).toLocaleString()}
            </span>
          </div>

          {/* Status/Timer */}
          <div className={`px-4 py-2 rounded-lg font-medium
            ${token.status === 'completed'
              ? isPositiveChange
                ? 'bg-green-500/20 text-green-400'
                : 'bg-red-500/20 text-red-400'
              : 'bg-blue-500/10 text-blue-400'
            }`}
          >
            {token.status === 'completed' ? (
              <CheckCircle className="w-5 h-5" />
            ) : (
              formatDuration(token.duration, token.durationType)
            )}
          </div>
        </div>
      </div>

      {/* Edit Button Overlay */}
      {isCompleted && onEdit && (
        <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(token);
            }}
            className="p-2 bg-blue-500/20 hover:bg-blue-500/40 rounded-lg text-blue-400 
              transition-all transform hover:scale-105"
          >
            <Edit2 className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TokenTemplate;